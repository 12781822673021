<template>
  <div class="register pb-5">
    <div class="container">
      <div class="row mb-5">
        <div class="col-sm-10 mx-auto text-center">
          <img src="@/assets/img/01. BR Official Opening Invite - Registration Form Banner.png" class="img-fluid"
            alt="banner" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-10 mx-auto">
          <h1 class="h4 mb-3 b-700 text-uppercase">Registration</h1>

          <div class="mb-4">
            <div class="mb-3">
              <label class="form-label">Name</label>
              <input v-model="participant.name" type="text" class="form-control" disabled />
              <span class="text-danger" v-for="message of validation_message.name" :key="`name-${message}`">{{ message
              }}<br /></span>
            </div>
            <div class="mb-3">
              <label class="form-label">Salutation</label>
              <input key="" v-model="participant.salutation" type="text" class="form-control" disabled />
              <span class="text-danger" v-for="message of validation_message.designation"
                :key="`designation-${message}`">{{ message }}<br /></span>
            </div>

            <div class="mb-3">
              <label class="form-label">Email address</label>
              <input type="email" v-model="participant.email" class="form-control" />
              <span class="text-danger" v-for="message of validation_message.email" :key="`email-${message}`">{{ message
              }}<br /></span>
            </div>
           
           
           
            <div class="d-flex flex-column gap-3 mb-3">
              <label class="form-label mb-0">Will you be joining us in this celebratory occasion?
              </label>
              <div class="d-flex">
                <div class="form-check form-check-inline mb-0">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions_celeb" id="celeb_radioYes"
                    value="yes" v-model="participant.celebratory_occasion" />
                  <label class="form-check-label" for="celeb_radioYes">Yes</label>
                </div>

                <div class="form-check form-check-inline mb-0">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions_celeb" id="celeb_radioNo"
                    value="no" v-model="participant.celebratory_occasion" />
                  <label class="form-check-label" for="celeb_radioNo">No</label>
                </div>
              </div>
              <span class="text-danger" v-for="message of validation_message.celebratory_occasion"
                :key="`celebratory_occasion-${message}`">{{ message }}<br /></span>
            </div>



            <div class="d-flex flex-column gap-3 mb-3">
              <label class="form-label">Ticket Type:
              </label>
              <select name="food" v-model="participant.ticket_type" class="form-select">
                <option value="Delegate">Delegate</option>
                <option value="Guest">Guest</option>
              </select>
              <span class="text-danger" v-for="message of validation_message.ticket_type"
                :key="`ticket_type-${message}`">{{ message }}<br /></span>
            </div>



            <div class="d-flex flex-column gap-3 mb-3">
              <label class="form-label mb-0">Will you be driving to the event?
              </label>
              <div class="d-flex">
                <div class="form-check form-check-inline mb-0">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="driv_radioYes" value="yes"
                    v-model="participant.driving" />
                  <label class="form-check-label" for="driv_radioYes">Yes</label>
                </div>

                <div class="form-check form-check-inline mb-0">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="driv_radioNo" value="no"
                    v-model="participant.driving" />
                  <label class="form-check-label" for="driv_radioNo">No</label>
                </div>
              </div>
              <span class="text-danger" v-for="message of validation_message.driving" :key="`driving-${message}`">{{
                message }}<br /></span>
            </div>

            <div class="mb-3" v-if="participant.driving == 'yes'">
              <label class="form-label">Vehicle No.</label>
              <input type="text" v-model="participant.vechile_no" class="form-control" />
              <span class="text-danger" v-for="message of validation_message.vechile_no"
                :key="`vechile_no-${message}`">{{ message }}<br /></span>
            </div>
            <!-- <div class="mb-3" v-if="participant.driving == 'yes'">
              <label class="form-label">IU No.</label>
              <input
                type="text"
                v-model="participant.iu_no"
                class="form-control"
              />
              <span
                class="text-danger"
                v-for="message of validation_message.iu_no"
                :key="`iu_no-${message}`"
                >{{ message }}<br
              /></span>
            </div> -->

            <!-- <div class="d-flex flex-column gap-3 mb-3">
              <label class="form-label mb-0"
                >Do you wish to receive SMS reminder on the event closer to
                event date?
              </label>
              <div class="d-flex">
                <div class="form-check form-check-inline mb-0">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions_sms"
                    id="sms_remain_yes"
                    value="yes"
                    v-model="participant.sms_remainder"
                  />
                  <label class="form-check-label" for="sms_remain_yes"
                    >Yes</label
                  >
                </div>

                <div class="form-check form-check-inline mb-0">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions_sms"
                    id="sms_remainno"
                    value="no"
                    v-model="participant.sms_remainder"
                  />
                  <label class="form-check-label" for="sms_remainno">No</label>
                </div>
              </div>
              <span
                class="text-danger"
                v-for="message of validation_message.sms_remainder"
                :key="`sms_remainder-${message}`"
                >{{ message }}<br
              /></span>
            </div> -->

            <div class="mb-3" v-if="participant.sms_remainder == 'yes'">
              <label class="form-label">Phone No.</label>
              <input type="text" v-model="participant.phone" class="form-control" />
              <span class="text-danger" v-for="message of validation_message.phone" :key="`phone-${message}`">{{ message
              }}<br /></span>
            </div>

            <div class="mb-3">
              <label class="form-label">Please indicate your dietary preference:
              </label>
              <select name="food" v-model="participant.dietary_preference" class="form-select">
                <option value="No Dietary Preference">
                  No Dietary Preference
                </option>

                <option value="vegetarian">Vegetarian</option>
                <option value="Halal">Halal</option>
              </select>
              <span class="text-danger" v-for="message of validation_message.dietary_preference"
                :key="`dietary_preference-${message}`">{{ message }}<br /></span>
            </div>
          </div>

          <div class="form-check ps-0">
            <input class="form-check-input" type="checkbox" id="condition_1" v-model="participant.condition" />
            <label class="form-check-label" for="condition_1">
              I agree to the below Terms & Conditions
            </label>
          </div>
          <span class="text-danger" v-for="message of validation_message.condition" :key="`condition-${message}`">{{
            message }}<br /></span>
          <h6 class="mt-2">Personal Data Protection Act (PDPA) Declaration</h6>
          <p class="mt-1">
            HomeTeamNS recognises its obligation under the Personal Data
            Protection Act 2012 (PDPA) which include the collection, use and
            disclosure of personal data (information provided above) which you
            have given consent to by the submission of this registration form
          </p>
          <p class="mt-1">
            Your personal data belonging to you may be used and disclosed to
            HomeTeamNS’ affiliated organisations, third party service providers
            for the following purposes:
          </p>
          <ol class="mb-5">
            <li class="mb-3">
              The information provided by me is true and correct.
            </li>
            <li>
              I consent to HomeTeamNS’s collection, use and disclosure of my
              personal data for the purposes set out in HomeTeamNS’s Data
              Protection Policy
              <a href="https://www.hometeamns.sg/pdpa/" target="_blank">https://www.hometeamns.sg/pdpa/</a>which
              outlines how HomeTeamNS manages my personal data in
              accordance with the Personal Data Protection Act 2012.
            </li>

            <li>
              Your personal data may be used and disclosed to HomeTeamNS’
              affiliated organisations, third party service providers for the
              following purposes:
              <ul>
                <li>
                  Register and contact you about your attendance at the event.
                </li>
                <li>
                  Carry out administrative, planning activities and to
                  facilitate your requirements for the event.
                </li>
                <li>
                  Compilation and analysis of statistics relevant to the event.
                </li>
              </ul>
            </li>
            <li>
              By attending the event above, you also consent to the use of
              photographs, voice, comments and image in any broadcast of this
              event and in subsequent productions drawn from video or audio
              recordings of this event. The photographs and recordings may be
              published or broadcasted in the official HomeTeamNS and HomeTeamNS
              affiliates' publications and in publicity materials, including the
              HomeTeamNS and HomeTeamNS affiliates' websites and social media.
              All recordings, articles, quotes, photographs, films, CCTV
              footage, audio or video and/or any reproductions of same in any
              form, are the property of HomeTeamNS.  HomeTeamNS and its
              affiliates, employees, representatives and agents are released
              from any and all claims, demands, costs and liability that may
              arise from the use of any of the described above, arising out of
              being interviewed, recorded, photograph, videotaped or filmed.
            </li>
            <!-- <li>
              Providing marketing material that you have agreed to receive.
            </li>
            <li>
              As a valued HomeTeamNS member/customer, you can expect HomeTeamNS
              and its affiliated organizations, service providers and programme
              partners to communicate to you via channels such as electronic
              direct mailers (Email), Short messaging service (SMS), direct
              mailers (Postal Mail), whatsapp and phone calls (Voice).
            </li> -->
          </ol>

          <p class="mb-2">
            **It is the responsibility of event participants to remove
            themselves from group and candid shots/recordings that the
            photographers/videographers intend to capture, if they do not wish
            to have their photos/audio-visual recordings taken at the event.**
          </p>
          <!-- <p class="mb-2">
            For more information on how personal data is processed within
            HomeTeamNS, please refer to our Data Protection Policy at
            <a href="https://www.hometeamns.sg/pdpa/" target="_blank"
              >https://www.hometeamns.sg/pdpa/</a
            >
          </p> -->
          <!-- <div class="form-check ps-0">
            <input
              class="form-check-input"
              type="checkbox"
              id="conition2"
              v-model="participant.second_condition"
            />
            <label class="form-check-label" for="conition2">
              By submitting this application, I confirm that
            </label>
          </div> -->
          <!-- <span
            class="text-danger"
            v-for="message of validation_message.second_condition"
            :key="`second_condition-${message}`"
            >{{ message }}<br
          /></span> -->

          <!-- <ol class="mb-5 mt-3">
            <li class="mb-3">
              the information provided by me is true and correct.
            </li>
            <li>
              I consent to SAFRA’s collection, use and disclosure of my personal
              data for the purposes set out in SAFRA’s Privacy Policy (<a
                href="https://www.safra.sg/privacy-policy"
                target="_blank"
                >https://www.safra.sg/privacy-policy</a
              >) [relating to membership / interest group / events/ use of
              services and facilities (as may be applicable) and SAFRA’s general
              business purposes], as amended from time to time, which outlines
              how SAFRA manages my personal data in accordance with the Personal
              Data Protection Act 2012.
            </li>
            <li>
              Where Personal Data of any third party is provided by me, I have
              obtained the consent of the third party to SAFRA’s collection, use
              and/or disclosure of those Personal Data.
            </li>
            <li>
              Photographs, videos and other recordings (“Media”) will be taken
              by SAFRA at this event. By attending this event, whether as a
              participant, staff or volunteer, you consent to and grant SAFRA
              the right to use such Media for internal records purposes,
              publicity purposes, commercial advertising and distribution to its
              sponsors and partners. Please note that non-official photographers
              might also operate at this event. SAFRA has no control over and is
              not responsible in any way for any Media produced by non-official
              photographers.
            </li>
          </ol> -->
          <div class="row">
            <div class="col-6 col-sm-2 mx-auto">
              <div class="d-grid">
                <button @click="store()" v-if="!loading" class="btn btn-primary text-uppercase" type="button">
                  Submit
                </button>
                <button class="btn btn-primary text-uppercase" disabled v-if="loading">
                  Please wait...
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Csrf from "@/assets/js/Csrf";
import Api from "@/assets/js/Api";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);
export default {
  name: "register",
  data() {
    return {
      loading: false,
      reg_key: "",
      rsvp: "",

      participant: {
        salutation: "",
        email: "",
        name: "",
        celebratory_occasion: "",
        ticket_type: "",
        driving: "",
        vechile_no: "",
        iu_no: "",
        dietary_preference: "",
        condition: "",
        second_condition: "",
        sms_remainder: "",
        phone: "",
        vip: 0,
      },
      validation_message: {
        salutation: [],
        email: [],
        name: [],
        celebratory_occasion: [],
        ticket_type: [],
        driving: [],
        vechile_no: [],
        iu_no: [],
        dietary_preference: [],
        condition: [],
        second_condition: [],
      },
    };
  },
  mounted() {
    this.reg_key = this.$route.params.reg_key;

    // Csrf.getCookie().then((res) => {
    Api.post(`participant/${this.reg_key}`)
      .then((res) => {
        console.log(res);
        if (res.data.error) {
          this.$router.push("/");
        }
        if (!res.data.error) {
          console.log("data", res.data.data);
          this.participant = res.data.data;
        }
      })
      .catch((error) => {
        this.$router.push("/");
      });
    // });
  },
  methods: {
    store() {
      this.loading = true;
      console.log("participant", this.participant);
      if (!this.participant.condition) this.participant.condition = "";
      if (!this.participant.second_condition)
        this.participant.second_condition = "";
      if (!this.participant.sms_remainder) this.participant.sms_remainder = "";
      // if (!this.participant.vip) {
      //   this.participant.driving = "---";
      // }

      // Csrf.getCookie().then((res) => {
      Api.post(`update-participant/${this.reg_key}`, this.participant).then(
        (res) => {
          if (res.data.validation_error) {
            this.validation_message = res.data.validation_message;
            this.loading = false;
            return;
          }
          if (!res.data.error) {
            this.loading = false;
            this.resetValidation();
            this.sucess();
          }
        }
      );
      // });
    },
    resetValidation() {
      this.validation_message = {
        salutation: "",
        email: "",
        name: "",
        celebratory_occasion: "",
        ticket_type: "",
        driving: "",
        vechile_no: "",
        iu_no: "",
        dietary_preference: "",
        condition: "",
        second_condition: "",
      };
    },
    sucess() {
      this.reg_key = "";
      this.participant.salutation = "";
      this.participant.name = "";
      this.participant.email = "";
      this.participant.celebratory_occasion = "";
      this.participant.ticket_type = "";
      this.participant.driving = "";
      this.participant.vechile_no = "";
      this.participant.iu_no = "";
      this.participant.dietary_preference = "";
      this.participant.second_condition = "";
      this.participant.condition = "";

      this.$swal({
        icon: "success",
        text: "Thank you for your response!",
        type: "success",
        confirmButtonColor: "#c21f19",
      }).then(function () { });
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  .form-label {
    font-weight: 600;
  }
}
</style>
